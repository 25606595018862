<template>
    <div class="col-12 mb-4">
        <div class="row mt-4 justify-content-center">
            <div class="col-10">
                <b-form-select class="col-12 col-md-2 col-lg-2 mb-4" v-model="year" :options="years" @change="filter"  />

                <b-card class="" header-tag="header">
                    <template #header>
                        <h6 class="mb-0 font-weight-bolder"> Forecasted Data </h6>
                    </template>

                    <ejs-grid :dataSource='item' :toolbar='toolbar' :toolbarClick='toolbarClick' height='273px'>
                        <e-columns>
                            <e-column field='year' headerText='Year' textAlign='Left' :isPrimaryKey='false' width=80></e-column>
                            <e-column field='month' headerText='Month' textAlign='Left' :isPrimaryKey='true' width=100></e-column>
                            <e-column field='clicks' textAlign='Left' headerText='Forecasted Clicks' width=240></e-column>
                            <e-column headerText='Site Impact (SI)' textAlign='Center' :columns='SI'></e-column>
                        </e-columns>
                    </ejs-grid>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { GridPlugin, Page, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
export default {
    name: "click_monitoring",
    props: ['item'],
    data() {
      return {
          year: new Date().getFullYear(),
          years: [
              { value: 2019, text: 2019 },
              { value: 2020, text: 2020 },
              { value: 2021, text: 2021 },
              { value: new Date().getFullYear(), text: new Date().getFullYear() },
          ],
          editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
          toolbar: [
             'Edit', 'Delete', 'Update', 'Cancel'
        ],
          SI: [
              { field: 'monthClicks', headerText: 'Total Clicks in Month', textAlign: 'Left' },
              { field: 'adAmount', headerText: 'ADR Estimated Amount', textAlign: 'Left' },
              { field: 'projectedClicks', headerText: 'Projected EOM Clicks', textAlign: 'Left' },
              { field: 'possible', headerText: 'ADRTBN Possible %', textAlign: 'Left', editType: 'numericedit' },
          ]
      }
    },
    // computed: {
    //     getData() {
    //         let data = [];
    //         data = this.$store.state.settings ? this.$store.state.settings.forecastedData : data;
    //         return data;
    //     },
    // },
    provide: {
        grid: [Page, Edit, Toolbar]
    },
    methods: {
        toolbarClick(args = ClickEventArgs) {
            switch (args.item.text) {
                /* tslint:disable */
                case 'Update':
                    this.$forceUpdate()
                    this.$emit( 'forecastUpdate' )
                break;
            }
        },
        filter(year) {
            this.$emit( 'filter', year)
        },
        load: function() {
            this.$refs.grid.ej2Instances.element.addEventListener('mouseup', function(e) {
                let instance = this.ej2_instances[0];
                if (e.target.classList.contains("e-rowcell")) {
                    if (instance.isEdit)
                        instance.endEdit();
                    let index = parseInt(e.target.getAttribute("Index"));
                    instance.selectRow(index);
                    instance.startEdit();
                }
            });
        }
    }
}
</script>

<style scoped>
/*@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";*/
</style>
