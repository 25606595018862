<template>
  <div class="col-12">
    <div class="row">
      <p>Crawler system settings configuration</p>
    </div>
    <div class="card p-3 mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <limiter :item="item" />
          <api :item="item" />
          <shown :item="item" />
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <queue :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import limiter from "@/components/settings/crawler/limiter";
import queue from "@/components/settings/crawler/queue";
import api from "@/components/settings/crawler/api";
import shown from "@/components/settings/crawler/shown";
export default {
  name: "crawler",
  props: ["item"],
  components: { limiter, queue, api, shown },
};
</script>

<style>
</style>