<template>
  <div class="col-12">
    <div class="row">
      <p>Additional system settings</p>
    </div>
    <div class="card p-3 mb-5">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Maintenance Mode</label>
            <select v-model="item.maintenance" class="form-control">
              <option value="0">Disabled</option>
              <option value="1">Enabled</option>
            </select>
            <div class="form-text text-muted">
              Enable maintenance
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Maintenance Title</label>
            <input
              type="text"
              v-model="item.maintenance_title"
              class="form-control"
            />
            <div class="form-text text-muted">
              Title of maintenance message
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Maintenance Message</label>
            <textarea
              type="text"
              v-model="item.maintenance_msg"
              class="form-control"
            ></textarea>
            <div class="form-text text-muted">
              Message to display to user of maintenance in progress
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label
              >Queue Maintenance for Active Campaign(s) Interval
              <small>(in minutes)</small></label
            >
            <input
              type="number"
              v-model="item.maintenance_active_interval"
              class="form-control"
            />
            <div class="form-text text-muted">
              placeholder text
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label
              >Queue Maintenance for Pending Campaign(s) Interval
              <small>(in minutes)</small></label
            >
            <input
              type="number"
              v-model="item.maintenance_pending_interval"
              class="form-control"
            />
            <div class="form-text text-muted">
              placeholder text
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Max TOS <small>in seconds</small></label>
            <b-form-input
              v-model="item.link_level_max_time"
              type="text"
              size="sm"
              autocomplete="off"
              style="height: 37px"
            ></b-form-input>
            <div class="form-text text-muted">
              Maximun time on site for all system campaigns
            </div>
          </div>
        </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="form-group">
                  <label>Proxy Loaded Scripts</label>
                  <input-tag v-model="item.exclusions"></input-tag>
                  <div class="form-text text-muted">
                      A list of scripts to be pulled over proxy by the crawler
                  </div>
              </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="form-group">
                  <label>Side Loaded Scripts</label>
                  <input-tag v-model="item.sideload_patterns"></input-tag>
                  <div class="form-text text-muted">
                      A list of scripts to be pulled over sideload by the crawler
                  </div>
              </div>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="form-group">
                  <label>Domain Blacklist</label>
                  <input-tag v-model="item.domain_blacklist"></input-tag>
                  <div class="form-text text-muted">
                      A list of domain paths to be blacklisted
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag)
export default {
  name: "addition",
  props: ["item"]
};
</script>

<style>
</style>
