<template>
  <div class="col-12">
    <div class="row">
      <p>Email server settings</p>
    </div>
    <div class="card p-3 mb-5">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Username</label>
            <input type="text" v-model="item.smtp_user" class="form-control" />
            <div class="form-text text-muted">
              Email user
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Host</label>
            <input type="text" v-model="item.smtp_host" class="form-control" />
            <div class="form-text text-muted">
              Email provider domain
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Password</label>
            <input
              type="password"
              v-model="item.smtp_password"
              class="form-control"
            />
            <div class="form-text text-muted">
              Email password
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Port</label>
            <input
              type="number"
              v-model="item.smtp_port"
              class="form-control"
            />
            <div class="form-text text-muted">
              Email provider domain access port
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Secure</label>
            <select v-model="item.smtp_secure" class="form-control">
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
            <div class="form-text text-muted">
              Enable secure mail connection
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smtp",
  props: ["item"],
};
</script>

<style>
</style>