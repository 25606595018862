<template>
  <div class="col-12">
    <div class="row">
      <h4 class="col-12">Shown Service</h4>
      <p class="col-12">Set start and stop time for the shown service.</p>
    </div>
    <div class="card p-3 mb-3">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Service Interval <small>(in milliseconds)</small></label>
            <input
              type="number"
              v-model="item.shownInterval"
              class="form-control"
            />
            <div class="form-text text-muted">
              Service update interval
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label
              >Service Interval (Capped and Shown Report)
              <small>(in milliseconds)</small></label
            >
            <input
              type="number"
              v-model="item.cappedAndShownInterval"
              class="form-control"
            />
            <div class="form-text text-muted">
              UI update interval
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Start time</label>
            <input
              type="time"
              v-model="item.shown_startTime"
              class="form-control"
            />
            <div class="form-text text-muted">
              Service turn on/start up time
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>End time</label>
            <input
              type="time"
              v-model="item.shown_stopTime"
              class="form-control"
            />
            <div class="form-text text-muted">
              Service turn off/shut down time
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shown",
  props: ["item"],
};
</script>

<style>
</style>