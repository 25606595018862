<template>
  <div class="col-12 mb-3">
    <div class="row">
      <h4 class="col-12">Queue API Targeting</h4>
      <p class="col-12">Set specific overrides for targeted campaigns.</p>
    </div>
    <div class="card p-3 mb-2">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Targeting</label>
            <select v-model="item.queueTargeting" class="form-control">
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <div class="form-text text-muted">
              Enable campaign targeting
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group" v-if="item.queueTargeting == 'yes'">
            <label>Queue Targeting</label>
            <input
              type="text"
              v-model="item.queueTargetingIds"
              class="form-control"
            />
            <div class="form-text text-muted">
              A comma separated list of tracking ids to target
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group" v-if="item.queueTargeting == 'yes'">
            <label>Queue Targeting Mode</label>
            <select v-model="item.queueTargetingMode" class="form-control">
              <option value="yes">Yes</option>
              <option value="nationwide">Nationwide</option>
              <option value="no">No</option>
            </select>
            <div class="form-text text-muted">
              The Queue Check IP mode to apply to the targets
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "api",
  props: ["item"],
};
</script>

<style>
</style>