<template>
    <div>
      <h2 class="border-bottom pb-2 mb-3">
        <i class="fa fa-bullseye"></i> System Snapshots
      </h2>
      <div class="row listing">
        <div class="col-12">
          <!--Place component here--->
          <div class="row mb-3" style="background-color: white; margin: 2px">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h6>Start Date</h6>
                            <div class="wrap">
                                <input type="datetime-local" class="form-control e-outline" id='startPicker' placeholder="start" v-model="filters.start" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h6>End Date</h6>
                            <div class="wrap">
                                <input type="datetime-local" class="form-control e-outline" id='startPicker' placeholder="start" v-model="filters.end" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <div style="margin-top: 26px;" class="wrap">
                                <button @click="search" type="button" class="btn btn-primary" >Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <hr>
          <div class="col-12">
              <div class="row">
                  <div class="col-lg-12 mb-4">
                      <b-table responsive :fields="fields" striped hover :items="items">
                          <template #cell(actions)="row">
                              <b-button @click="show(row)" v-b-modal.modal-settings variant="primary">Show Settings</b-button>
                              <b-button @click="show(row)" v-b-modal.modal-services variant="primary">Show Services</b-button>
                          </template>
                          <template #cell(services)="row">
                              {{row.item.services.length}}
                          </template>
                      </b-table>
                  </div>
                  <div class="col-lg-12">
                      <div class="d-flex justify-content-center">
                          <b-pagination
                              @input="search"
                              v-model="filters.currentPage"
                              :total-rows="count" :per-page="filters.perPage"/>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
          </div>
        <b-modal id="modal-settings" size="xl" title="Settings">
            <settings-modal :item="snapshot.settings"/>
        </b-modal>
  
        <b-modal id="modal-services" size="xl" title="Services">
            <services-modal :services="snapshot.services"/>
        </b-modal>
  
  
    </div>
  </template>
  
  <script>
  import swal from "sweetalert";
  import axios from "axios";
  import moment from "moment"
  import SettingsModal from "@/components/snapshots/settings-modal";
  import ServicesModal from "@/components/snapshots/services-modal";
  export default {
    name: "snapshots.vue",
      components: {ServicesModal,SettingsModal},
      data(){
        return{
            filters: {
                start: "",
                end: "",
                perPage: 12,
                currentPage: 1,
            },
            fields: [
                {
                    key: 'created_ts',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'Services',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Actions',
                }
            ],
            items:[],
            snapshot:{},
            count:0,
        }
      },
      async created(){
        this.setDefaults()
          await this.search()
      },
      methods:{
        setDefaults(){
            this.filters.start = moment().startOf("day").format().substring(0,16)
            this.filters.end = moment().endOf("day").format().substring(0,16)
        },
          async search( page){
            try{
                if(page){
                    console.log('page: ',page)
                }
                const response = await axios.get(`${this.$root.serverUrl}/static/logsettings`, {params: this.filters, json: true})
                this.items = response.data.data
                this.count = response.data.count
                this.$root.preloader = false;
            }catch (e) {
                console.log(e)
                this.$root.preloader = false;
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'An error occurred getting the data',
                })
            }
          },
          show(row){
            this.snapshot = row.item;
              setTimeout(function (){
  
                  // Something you want delayed.
                  let elements = ['input','select','textarea']
                  elements.forEach((el)=>{
                      var x = document.querySelectorAll(el)
                      console.log('els',x)
                      x.forEach(field=>{
                          console.log(field.type)
                          if(field.type !== 'datetime-local'){
                              field.disabled = true;
                          }
                      })
                  })
              }, 500);
            console.log('Settings Click')
          }
      }
  }
  </script>
  
  <style scoped>
  /deep/ .modal-dialog {
      min-width: 95% !important;
      min-height: 700px !important;
      max-height: 700px !important;
  }
  </style>
  