<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i> Settings</h2>
        <div class="col-12" style="background-color: white; padding: 2%">
            <div class="row">
                <b-tabs class="col-12" align="center" pills>
                    <b-tab title="System Information" active>
                        <system :item="item"
                        /></b-tab>
                    <b-tab title="Crawler Settings"> <crawler :item="item" /></b-tab>
                    <b-tab title="Pitcher Settings"><pitchers :item="item" :colors="colors" /></b-tab>
                    <b-tab title="Provider Settings">
                        <providers :item="item" :providers="item.providers" :colors="colors" />
                    </b-tab>
                    <b-tab title="SMTP Settings"> <smtp :item="item" /></b-tab>
                    <b-tab title="Addition Settings"> <addition :item="item" /></b-tab>
                    <b-tab title="Click Monitoring"> <clickmonitortab :item="item.forecastedData" /></b-tab>
                </b-tabs>
            </div>
        </div>
        <div class="row editor">
            <div class="col-12">
            </div>
        </div>
    </div>
</template>

<script>
import system from "@/components/settings/system";
import crawler from "@/components/settings/crawler";
import providers from "@/components/settings/providers";
import smtp from "@/components/settings/smtp";
import addition from "@/components/settings/addition";
import pitchers from "@/components/settings/pitcher";
import clickmonitortab from "./clickmonitortab.vue";
export default {
    name: "settings-modal",
    components: { system, crawler, providers, smtp, addition, pitchers,clickmonitortab },
    props: {
        item:{
            // default: {
            //     providers: [],
            //     proxy: {
            //         didsoft: {},
            //         flipnode: {},
            //         homeip: {},
            //         proxyguys: {},
            //     },
            //     queueTargeting: "no",
            // },
        }
    },
    data(){
        return{
            defaultsettings: {
               // providers: [],
                proxy: {
                    didsoft: {},
                    flipnode: {},
                    homeip: {},
                    proxyguys: {},
                },
                exclusions: [],
                queueTargeting: "no",
            },
            colors: [
                { name: "Red", code: "red-circle-lv" },
                { name: "Blue", code: "blu-circle-lv" },
                { name: "Green", code: "grn-circle-lv" },
                { name: "Yellow", code: "ylw-circle-lv" },
                { name: "White", code: "wht-circle-lv" },
                { name: "Purple", code: "purple-circle-lv" },
            ],
            //providers: [],
        }
    },
}
</script>

<style scoped>

</style>
