<template>
    <div class="row">
        <div class="col-12">
                <div class="row mb-3">
                    <div class="col-12 col-md-3">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4 v-if="services.length > 1" class="m-0">Active - {{services.filter(x => x.status === 'online').length }}</h4>
                                <h4 v-else class="m-0">Loading..</h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-3 mt-2 mt-md-0">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4 v-if="services.length > 1" class="m-0">Total - {{services.length}}</h4>
                                <h4 v-else class="m-0">Loading..</h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 mt-2 mt-md-0">
                        <div class="card">
                            <div class="card-body text-center" >
                               <div v-if="services.length > 1" class="row" style="overflow-x: scroll; max-height: 133px !important; white-space: nowrap;">
                                   <div class="col-6 col-md-6">
                                        <div v-for="(item,index) in services" :key="index">
                                            <h5 v-if="item.status === 'online'" class="m-0">{{item.name.toUpperCase()}} - {{services.filter(x => x.status === 'online' && x.name === item.name).length}}</h5>
                                        </div>
                                       
                                   </div>
                               </div>
                                <div v-else class="row text-center">
                                   <div class="col-12">
                                       <h4 class="m-0 ">Loading..</h4>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="col-lg-12">
            <b-table :fields="fields" responsive :items="services"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "services-modal",
    props:{
        services:{
            default: []
        }
    },
    data(){
        return {
            fields: [
                {
                    key: 'host',
                    label: 'Host',
                    sortable: true
                },
                {
                    key: 'pid',
                    label: 'Pid',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'mode',
                    label: 'Mode',
                    sortable: true
                },
                {
                    key: 'instances',
                    label: 'Instances',
                    sortable: true
                },
                {
                    key: 'path',
                    label: 'Path',
                    sortable: true
                },

            ],
        }
    }
}
</script>

<style scoped>
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
</style>
