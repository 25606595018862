<template>
  <div class="col-12">
    <div class="row">
      <p>Proxy providers</p>
    </div>
    <div class="card p-3 mb-5">
      <div class="row mt-3">
        <div class="col-sm-4 col-md-4 col-lg-2" v-for="(provider, index) in providers" :key="index">
          <div class="col-12">
            <div class="form-group">
              <label>{{
                provider.name
              }}</label>
              <input
                type="checkbox"
                class="ml-2"
                v-model="provider.active"
                :value="provider"
              />

            </div>
            <div class="form-group">
              <select v-model="provider.color" class="form-control">
                <option value="">None</option>
                <option
                  v-for="(color, cindex) in colors"
                  :key="cindex"
                  :value="color.code"
                >
                  {{ color.name }}
                </option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "providers",
  props: ["item", "providers","colors"],
};
</script>

<style>
</style>