<template>
  <div class="col-12">
    <div class="row">
      <h4 class="col-12">Crawler Limiter</h4>
      <p class="col-12">Set a min and max target for the limiter.</p>
    </div>
    <div class="card p-3 mb-3">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Min Target</label>
            <input
              type="number"
              v-model="item.target_min"
              class="form-control"
            />
            <div class="form-text text-muted">
              Minimum daily target
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Max Target</label>
            <input
              type="number"
              v-model="item.target_max"
              class="form-control"
            />
            <div class="form-text text-muted">
              Maximum daily target
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Target Percentage</label>
            <input
              type="number"
              v-model="item.target_percentage"
              step=".0001"
              class="form-control"
            />
            <div class="form-text text-muted">
              Percentage of target maximum value
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Need Limit</label>
            <input
              type="number"
              v-model="item.needLimit"
              step="1.0"
              class="form-control"
            />
            <div class="form-text text-muted">
              Needs minimum value
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Need Max</label>
            <input
              type="number"
              v-model="item.needMax"
              step="1.0"
              class="form-control"
            />
            <div class="form-text text-muted">
              Needs maximum value
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Pitcher Mode</label>
            <select v-model="item.pitcher.mode" class="form-control">
              <option value="" selected>Please Select</option>
              <option
                value="city"
              >
                City
              </option>
              <option
                value="county"
              >
                County
              </option>
              <option
                value="zip"
              >
                ZipCode
              </option>
              <option
                value="api"
              >
                API
              </option>
              <option
                value="nationwide"
              >
                Nationwide
              </option>
            </select>
            <div class="form-text text-muted">Selects targeting mode (applies to smart only)</div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Checked Minutes</label>
            <input
              type="number"
              v-model="item.pitcher.checkedMinutes"
              step="1.0"
              min="0"
              max="15"
              class="form-control"
            />
            <div class="form-text text-muted">
              Proxy wait minutes until next usage. Range (0-15)
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Proxy Accuracy</label>
            <input
              type="number"
              v-model="item.pitcher.accuracy"
              step="1.0"
              min="0"
              max="100"
              class="form-control"
            />
            <div class="form-text text-muted">
              Proxy accuracy. Range (0-100)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "limiter",
  props: ["item"],
    created() {
        if (!this.item.pitcher) this.item.pitcher = {}
    },
};
</script>

<style>
</style>
