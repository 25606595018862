<template>
  <div class="col-12">
    <div class="row">
      <p>Core system settings</p>
    </div>
    <div class="card p-3 mb-5">
    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>System Name</label>
          <input
            type="text"
            v-model="item.system"
            class="form-control"
            value="Voip Portal"
            required
          />
            <div class="form-text text-muted">
              Platform name
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>Company</label>
          <input
            type="text"
            v-model="item.company"
            class="form-control"
            value=""
            required
          />
            <div class="form-text text-muted">
              Owner name
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>System Domain</label>
          <input
            type="text"
            v-model="item.domain"
            class="form-control"
            required
          />
            <div class="form-text text-muted">
              System portal entry point
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>System Copyright</label>
          <input
            type="text"
            v-model="item.copyright"
            class="form-control"
            required
          />
            <div class="form-text text-muted">
              Date copyright enforced
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>System Email</label>
          <input
            type="text"
            v-model="item.email"
            class="form-control"
            required
          />
            <div class="form-text text-muted">
              Contact email informatiom
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>System Error Recipients</label>
          <input type="text" v-model="item.email_errors" class="form-control" />
            <div class="form-text text-muted">
              System error contact person
            </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="form-group">
          <label>Comments/Feedback Recipients</label>
          <input
            type="text"
            v-model="item.email_feedback"
            class="form-control"
          />
            <div class="form-text text-muted">
              Person to forward comments and feedback
            </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "system",
  props: ["item"],
};
</script>

<style>
</style>
