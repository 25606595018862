<template>
  <div class="col-12">
    <div class="row">
      <h4 class="col-12">Queue Configuration</h4>
      <p class="col-12">
        Configure the queue for the best worker performance..
      </p>
    </div>
    <div class="card p-3 mb-3">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Service Percentage</label>
            <input
              type="number"
              v-model="item.service_percentage"
              step=".0001"
              class="form-control"
            />
            <div class="form-text text-muted">Percentage of delivery need.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Service Interval</label>
            <input
              type="number"
              v-model="item.serviceInterval"
              step=".0001"
              class="form-control"
            />
            <div class="form-text text-muted">placeholder text</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Mode</label>
            <input type="text" v-model="item.queueMode" class="form-control" />
            <div class="form-text text-muted">placeholder text</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Max</label>
            <input type="number" v-model="item.queueMax" class="form-control" />
            <div class="form-text text-muted">Clicksneeded count limiter.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Interval</label>
            <input
              type="number"
              v-model="item.queueInterval"
              class="form-control"
            />
            <div class="form-text text-muted">Queue cycle interval in seconds.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Buster Max</label>
            <input
              type="number"
              v-model="item.queueBusterMax"
              class="form-control"
            />
            <div class="form-text text-muted">placeholder text</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Percentage</label>
            <input
              type="number"
              v-model="item.queue_percentage"
              class="form-control"
            />
            <div class="form-text text-muted">Limit queue max by percentage.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Check Ip</label>
            <select v-model="item.queueCheckIp" class="form-control">
              <option value="yes">Yes</option>
              <option value="nationwide">Nationwide</option>
              <option value="no">No</option>
            </select>
            <div class="form-text text-muted">Target location ip check.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Alternate</label>
            <select v-model="item.queueAlternate" class="form-control">
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
            <div class="form-text text-muted">Alternate between Queue check ip options.</div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Queue Pitch</label>
            <select v-model="item.queuePitch" class="form-control">
              <option value="blitz">Blitz</option>
              <option value="quality">Quality</option>
              <option value="speed">Speed</option>
            </select>
            <div class="form-text text-muted">Queue activity mode.</div>
          </div>
        </div>
        <div class="accordion col-sm-12 col-md-12 col-lg-12" role="tablist">
          <b-button
            class="btn-primary"
            block
            v-b-toggle="['cc-provider']"
            style="background-color: #007bff; border-color: #ffe9e9"
            >Default Providers</b-button
          >
          <b-collapse id="cc-provider">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Queue Homeip</label>
                  <select v-model="item.queueHomeip" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Queue Geosurf</label>
                  <select v-model="item.queueGeosurf" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Queue Netnut</label>
                  <select v-model="item.queueNetnut" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <label>Queue Oxylabs</label>
                  <select v-model="item.queueOxylabs" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "queue",
  props: ["item"],
};
</script>

<style>
</style>