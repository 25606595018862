<template>
  <div class="col-12">
    <div class="row">
      <h4 class="col-12">Pitcher Settings</h4>
      <p class="col-12">Set a concurrency and percentage of pitcher.</p>
    </div>
    <!-- <div class="row">
        {{this.$root.$data.countries}}
    </div> -->
    <div class="card p-3 mb-3">
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Concurrency</label>
            <input
              type="number"
              v-model="item.pitcher.concurrency"
              class="form-control"
              value="Voip Portal"
              required
            />
            <div class="form-text text-muted">Pitcher Concurrency Value</div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>Percentage</label>
            <input
              type="text"
              v-model="item.pitcher.percentage"
              class="form-control"
              value=""
              required
            />
            <div class="form-text text-muted">
              Pitcher Nationwide Percentage Value
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>QSO Hits</label>
            <input type="text" class="form-control" :value="getQSO" disabled />
            <div class="form-text text-muted">
              Pitcher Hits QSO Server Per Sec
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>QSO Min</label>
            <input
              type="number"
              v-model="item.pitcher.qso_min"
              class="form-control"
            />
            <div class="form-text text-muted">Pitcher Job Min Timeout Value in milliseconds </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <div class="form-group">
            <label>QSO Max</label>
            <input
              type="text"
              v-model="item.pitcher.qso_max"
              class="form-control"
            />
            <div class="form-text text-muted">
              Pitcher Job Max Timeout Value in milliseconds 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <h4 class="col-12">Pitcher Targeting Settings</h4>
      <p class="col-12">Set a State and percentage of pitcher.</p>
    </div>
    <div class="card p-3 mb-5">
      <div class="row">
        <div class="col-sm-4 col-md-5 col-lg-5">
          <div class="form-group">
            <label>Add Targeting</label>
            <select v-model="newItem.code" class="form-control">
              <option value="" selected>Please Select</option>
              <option
                :value="code.code"
                v-for="(code, dex) in getStates"
                :key="dex"
              >
                {{ code.name }}
              </option>
            </select>
            <div class="form-text text-muted">Set a State</div>
          </div>
        </div>
        <div class="col-sm-4 col-md-5 col-lg-5">
          <div class="form-group">
            <label>Queue Targeting</label>
            <input
              type="text"
              v-model="newItem.percentage"
              class="form-control"
              placeholder="20"
            />
            <div class="form-text text-muted">Set a percentage value</div>
          </div>
        </div>
        <div class="col-sm-4 col-md-2 col-lg-2">
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary mt-4"
              @click.prevent="addState()"
            >
              Add Target
            </button>
            <div class="form-text text-muted">
              Apply to the targets list below
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div
          class="row col-sm-12 col-md-12 col-lg-12"
          v-for="(target, index) in getTargetStates"
          :key="index"
        >
          <div class="form-group col-sm-4 col-md-5 col-lg-5">
            <label>Targeting</label>
            <select v-model="target.code" class="form-control">
              <option value="N/A">No</option>
              <option
                :value="code.code"
                v-for="(code, dex) in getStates"
                :key="dex"
              >
                {{ code.name }}
              </option>
            </select>
            <div class="form-text text-muted">
              Enable campaign targeting for state code {{ target.code }}
            </div>
          </div>
          <div class="form-group col-sm-4 col-md-5 col-lg-5 mt-3">
            <input
              type="text"
              v-model="target.percentage"
              class="form-control mt-3"
              required
            />
            <div class="form-text text-muted">
              campaign targeting percentage
            </div>
          </div>
          <div class="form-group col-sm-4 col-md-2 col-lg-2 mt-3">
            <button
              type="button"
              class="btn btn-primary mt-3"
              @click.prevent="removeState(index)"
            >
              Remove 
            </button>
            <div class="form-text text-muted">Remove target from list</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pitcher",
  props: {
    item: {
      type: Object,
      default: ()=>{
        return { pitcher: { targets: [] } }
      } 
    }, 
    save:{
      type: Object,
      default:()=>{return { }}
    }
  },
  data: () => {
    return {
        newItem: {
            code: "",
            percentage: 10,
        },
        targets: [],
    };
  },
  created() {
      if (!this.item.pitcher) this.item.pitcher = {}
    this.item.pitcher.targets = this.item && this.item.pitcher.targets || [];
  },
  mounted() {
    //   this.targets = this.item.pitcher.targets?this.item.pitcher.targets:this.targets;
  },
  computed: {
    getQSO() {
      return ((1 / 55) * (2 * Number(this.item && this.item.pitcher && this.item.pitcher.concurrency ? this.item.pitcher.concurrency : 0))).toFixed(2);
    },
    getSettings() {
      return this.$store.state.settings;
    },
    getStates() {
      return this.$root.$data.states;
    },
    getTargetStates() {
      return this.item.pitcher.targets || [];
    },
  },
  methods: {
    calQSO: (value) => {
      return ((1 / 55) * (2 * value)).toFixed(2);
    },
    addState() {
        console.log(this);
        let data = this.getStates;
        console.log(data);
        data = this.item.pitcher.targets.filter((item) => item.code === this.newItem.code)[0];
        console.log(data);
        if (data) return;
        this.$forceUpdate();
    //   this.newItem.code = data.code;
        this.item.pitcher.targets.push({...this.newItem});
      //   if(this.save){
      //       this.save();
      //   }
    },
    removeState(value) {
        this.item.pitcher.targets.splice(value, 1);
        this.$forceUpdate();
      //   if(this.save){
      //       this.save();
      //   }
    },
  },
};
</script>

<style>
</style>
